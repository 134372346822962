body {
  margin: 0;
  font-family: MyriadWebPro, Avenir, sans-serif;
  font-weight: 400;
  font-size: 9px;
  width: 100%;
  padding: 0px;
  text-align: center;
  line-height: 1.2;
  font-style: inherit;
  background-color: #fdfdfd;
  letter-spacing: 0.0175em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 92%;
  overflow-y: scroll;
}
b {
  text-transform: capitalize;
}
a {
  font-size: 13px;
  font-weight: 600;
  margin: 10px auto;
}

#card {
  font-size: 10px;
  box-shadow: none;
  padding: 10px;
  border: none;
}

#logo {
  width: 154.66px;
  padding: 15px 0;
  margin-left: -15px;
  margin-top: -15px;
  filter: contrast(1);
}

#primary {
  color: #2cb5fa;
}
#primary-bold {
  color: #2cb5fa;
  font-weight: 800;
}
#bold-primary {
  color: #2cb5fa;
  font-weight: 800;
}
#bold-secondary {
  color: #001269;
}
#secondary {
  color: #001269;
}
#red {
  color: #dc274bdb;
}
#target-green {
  color: rgb(49, 209, 155);
}

.qm-title {
  text-align: left;
  padding: 20px 0 0px 0px;
}

#title {
  font-size: 32px;
  padding-top: 0px;
  color: #001269;
  letter-spacing: 0.01em;
}
#composite-title {
  font-size: 24px;
  color: #001269;
  letter-spacing: 0.01em;
}
#composite-copy {
  margin: 0 25% 10px 25%;
  font-size: 12px;
}

#pcp {
  color: #2cb5fa;
  font-size: 12px;
  font-weight: 600;
}

small {
  color: #62717a;
  font-size: 10px;
  letter-spacing: 0.1em;
}

label {
  text-transform: uppercase;
  margin: 2px 4px 2px 0px;
  letter-spacing: 0.025em;
  font-size: 11px;
  line-height: 30px;
}
span {
  color: #001269;
  line-height: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 400;
  color: inherit;
}

b#titleCase {
  color: #001269;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0;
  letter-spacing: 0.1em;
}

b#thin {
  color: #001269;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1em;
}
b {
  color: #001269;
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0.1em;
}

p {
  margin: 10px auto;
  font-size: 9px;
  padding: 5px 5px 5px 1px;
  letter-spacing: 0.01em;
  line-height: 1.5;
}

h1 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
  margin: 0px 0;

  color: #001269;
}

h4 {
  color: #001269;

  font-size: 17px;
}

h5 {
  font-size: 14px;
  padding: 5px;

  padding: 5px 10px;
  text-transform: uppercase;
  color: #001269;
}

h6 {
  font-size: 12px;
  text-transform: uppercase;
}

.alert {
  margin: 10% 5% 0;
  padding: 20px 0 0;
}
.alert-msg {
  padding: 5px 10px;
}

.loading {
  letter-spacing: 0.08em;
  margin-top: 25vh;
  margin-left: 5vw;
  color: #001269;
  line-height: 27px;
  font-size: 2.225rem;
  z-index: 1000;
  text-align: center;
}
#loading-spinner {
  margin: 0px 1px 0px 1px;
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.pcp {
  margin: -3px 0px -1px;
}
.primary-light {
  color: #b0c2dd;
}
.dark {
  color: #001269;
}
.secondary {
  color: #62717a;
}

.button-group {
  padding: 10px;
  margin: 5px auto 20px;
}
.route-link {
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.1em;
  font-size: 12px;
  font-weight: 500;
  margin: 3px;
  border-radius: 50px;
  background: #071556;
  color: white;
  padding: 10px 15px;
}
.route-link:hover {
  background: #b0c2dd;
}

.route-disabled {
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.1em;
  font-size: 12px;
  font-weight: 500;
  margin: 3px auto;
  border-radius: 50px;
  background: #b9bccb;
  pointer-events: none;
  color: #fff;
  padding: 10px 15px;
}
.card-404 {
  margin: 60px auto auto;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 4%);
  border: 1px solid ghostwhite;
  padding: 30px 0;
  width: 88vw;
}

.header {
  font-weight: 700;
  font-size: 3.7rem;
  margin: 60px auto auto;
}

.header-secondary {
  font-weight: 900;
  font-size: 1.8rem;
  margin: 20px;
  text-transform: uppercase;
}

.divider {
  margin: 10px 0;
}
.p {
  padding: 20px;
}

.small {
  flex-grow: 0;
}

.page-link[disabled] {
  color: #00000026;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.page-link[disabled]:hover {
  color: #00000026;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.page-link:hover {
  background-color: rgb(241, 241, 241);
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: ghostwhite;
  border: 0px solid #00126917;
}
.page-link:focus {
  border: 0px solid #00126917;
  background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: ghostwhite;
}

.page-link {
  height: 28px;
  padding: 5px;
  background-color: rgba(246, 246, 246, 0);
  box-shadow: none;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0px solid #00126917;
}
.input:focus {
  background-color: rgba(246, 246, 246, 0.437);
  background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #00126926;
  outline: ghostwhite;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 5%);
  border-radius: 20px;
}
.input {
  flex-grow: 1;
  margin-right: 3px;
  padding: 0px 10px;
  height: 27px;
  letter-spacing: 0.06em;
  min-width: 300px;
  border: 1px solid #70717517;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 20px;
}
.donut-grid {
  z-index: 0;
  margin: 20px auto 10px;
}

.total-amount {
  z-index: -1;
}

#back-btn {
  position: absolute;
  box-shadow: 1px 1px rgb(69 90 100 / 7%);
  left: 1rem;
  top: 7rem;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 100%;
  color: #001269;
  margin: 50px 0 -20px 15px;
}

#back-icon {
  color: #001269;
  font-size: 71px;
}

#print-btn {
  font-size: 12px;
  color: #001269;
  border-radius: 30px;
  margin: 10px;
  border: 1px solid #7071752a;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  height: 32px;
  z-index: 1000;
}

#established-btn {
  font-size: 12px;
  font-weight: 600;
  border-radius: 30px;
  margin: 10px;
  border: 1px solid #7071752a;
  letter-spacing: 0.1em;
  padding: 12px;
  height: 32px;
  z-index: 1000;
}
#print-chart-btn {
  position: absolute;
  top: 10px;
  z-index: 100;
  right: 10px;
  color: #001269;
  padding: 7px;
  font-size: 9px;
  letter-spacing: 0.1em;
  border-radius: 30px;
}

#print-icon {
  font-size: 17px;
  margin: 0 2px 0px 5px;
}
#print-chart-icon {
  font-size: 16px;
  margin: 0 2px 3px 2px;
}

#search-input {
  position: relative;
  width: 400px;
}
#clear {
  border: none;
  color: #62717a;

  font-size: 14px;
  font-weight: 700;
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
}

#clear:hover {
  background-color: ghostwhite;
}

#margin-btm {
  margin-bottom: 32px;
}

#toolbar {
  margin-left: 12px;
}
#dashboard {
  margin: 20px 3%;
  padding: 20px 0;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 4%);
  border: 1px solid ghostwhite;
}
#uppercase {
  text-transform: uppercase;
}
#alert-dashboard {
  margin: 50px 3%;
  padding: 20px 0;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 4%);
  border: 1px solid ghostwhite;
}
#qm-dashboard {
  border-radius: 3px;
  padding-bottom: 0px;
  padding: 30px 15px 20px;
  background-color: #ffffff;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 4%);
  /* border: 1px solid ghostwhite; */
}
#qm-filters-text {
  font-size: 11px;
}

#qm-grid-item {
  padding: 25px 10px;
}

#graph-card {
  padding: 10px;
  position: relative;
  text-align: start;
  border-radius: 0;

  box-shadow: none;
}
#nps-graph-card {
  margin: 0px;
  padding: 0 10px;
  box-shadow: none;
  border: 1px solid ghostwhite;
}

#graph-box {
  padding: 20px 45px 0px 20px;
  font-size: 12px;
  height: 10px;
  position: relative;
  text-align: end;
}
#graph-box-l {
  padding: 20px 45px 0 20px;
  font-size: 12px;
  position: relative;
  text-align: left;
}

#input-box {
  text-align: left;
  padding: 10px;
  font-size: 12px;
  background: #62717a0d;
}
#input-label {
  color: #62717a;
  font-size: 12px;
}

#graph-text-box {
  text-align: left;
}
#key-dot {
  position: absolute;
  font-size: 2.7rem;
  border-radius: 50%;
  font-weight: 900;
  line-height: 0px;
}

.on-wP {
  color: #001269;
}

.not-on-wP {
  color: #62717a;
}

.all {
  color: #2cb5fa;
}

#key-dash.target {
  font-size: 3rem;
  font-weight: 900;
  position: absolute;
  line-height: 0px;
  color: #1de9b6;
  margin: 6px 0px 0px -40px;
}

label#graph-box-label {
  position: absolute;
  top: 15px;
  text-transform: none;
  line-height: 10px;
  right: 45px;
}

label#graph-title {
  font-size: 12px;
}
#target-pill {
  z-index: 2;
  position: absolute;
  padding: 1px 6px;
  font-size: 10px;
  font-weight: 800;
  top: 0px;
  left: 0px;

  color: #0bdaa6;
  letter-spacing: 0.14em;
}

p#graph-cohort-label {
  font-size: 12px;
  margin: 4px 10px 0 10px;
}
#divider {
  margin: 0 0 5px;
}
#pe-bar {
  padding: 10px 0 0px 0px;
  position: relative;
  max-height: 350px;

  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
#pe-bar-nps {
  max-height: 500px;
  padding: 10px;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#pcp-bar {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 20px 0 20px;
  width: 60%;
  max-height: 400px;
}
#bar {
  position: relative;

  max-height: 500px;
  padding-right: 20px;
}
#line-graph {
  position: relative;

  max-height: 500px;
}
.select-plan-sponsor {
  z-index: 2000;
}
select.default {
  padding: 0 0 0 5px;
  letter-spacing: 0.06em;
  height: 30px;
  width: 300px;
  box-shadow: none;
  border: 1px solid #7071752a;
  margin: 2px;
  font-size: 10px;
  font-weight: 200;
  line-height: 1;
  text-align: center;
  color: #2f2c2c;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 20px;
}
select.default:hover {
  border: 1px solid #5b5c5f60;
}
.default option:hover {
  background-color: rgba(246, 246, 246, 0.437);
  background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #00126926;
  outline: ghostwhite;
  box-shadow: 1px 1px 1px rgb(69 90 100 / 5%);
  border: 1px solid #00126926;
}

.default:focus {
  background-color: rgba(246, 246, 246, 0.437);
  outline: ghostwhite;
  background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #00126926;
}

#center {
  text-align: center;
}

#divider {
  border: none;
  height: 5px;
  border-bottom: 1px solid rgb(232 232 232 / 59%);
  margin: 0;
  background-color: transparent;
  flex-shrink: 0;
}
/******************  TABLES *************************/
th {
  text-align: left;
  padding: 5px;
  font-weight: 500;
}

td {
  padding: 5px;
  position: relative;
  line-height: 1.4;
  font-weight: 400;
  font-size: 9px;
}
table {
  text-align: start;
  border-spacing: 0px;
  font-size: 9px;
  width: 95%;
}

#left-align {
  margin: auto auto auto 10px;
}

#right {
  text-align: right;
}
#left {
  text-align: left !important;
}
#responses-table {
  text-align: start;
  width: auto;
  margin: auto;
}

#survey-name {
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
table#pe-nps-breakdown {
  font-size: 12px;
  margin-top: 30px;
}

td#cohort {
  width: 85px;
  margin-left: -10px;
  text-align: left;
}

td#key {
  width: 5px;
  text-align: left;
  position: relative;
}

th#label {
  max-width: 90px;
}
td#label {
  max-width: 90px;

  font-weight: 400;
  font-size: 12px;
}
table#pe {
  font-size: 12px;
  padding: 0 0px 0 0px;
}

tr#trExpand:hover {
  background-color: #62717a0d;
  border-bottom: 3px solid #6d6d6d;
  background-color: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
td#td-comment {
  border-bottom: 1px solid ghostwhite;
}

table#comments {
  margin: 0;
}
table#comments thead {
  padding: 10px 20px 5px 0;
}

table#comments th {
  border-bottom: 1px solid ghostwhite;
}
table#comments tr {
  border-bottom: 0px solid ghostwhite;

  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
}
table#comments td {
  border-bottom: 0px solid ghostwhite;
  padding-top: 10px;
  padding-bottom: 7px;
}

table#comments-header {
  border-bottom: 1px solid ghostwhite;
  border-top: none;
}
table#nps {
  border-top: none;
  margin: 0;
  padding: 0px 20px 10px;
}

td#nps-score-comments {
  font-weight: 500;
}

table#qm {
  font-size: 9px;
  margin: 30px 0 20px 0px;
  border-radius: 5px;
  float: right;
  text-align: right;
  /* border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb; */
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

table#qm td {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
table#qm th {
  background-color: #f6f6f8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

span#td {
  padding-right: 10px;
  color: #2f2c2c;
}
span#value {
  font-weight: bold;
}

#container {
  padding: 0 0px 0 10px;
  border: 1px solid ghostwhite;
  margin-top: 0px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

/************** ICONS ********************/
#big-big-icon {
  font-size: 180px;
  margin-bottom: -50px;
  color: #b0c2dd52;
}
#big-icon {
  font-size: 70px;
}
#btn-icon {
  margin-bottom: -4px;
  font-size: 18px;
  margin-right: 3px;
}
#person-icon {
  font-size: 35px;
  color: #b0c2dd;
  margin: 0px auto -10px;
}
#search {
  width: 16px;
  margin-right: 5px;
  margin-bottom: -10px;
}
#qm-title-button {
  text-transform: uppercase;
  color: black;
}
#list-icon {
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}
#composite-icon {
  font-size: 20px;
  margin-right: 3px;
  margin-bottom: -6px;
  transform: rotate(90deg);
}
#icon {
  font-size: 20px;
  margin-right: 3px;
  margin-bottom: -6px;
}

#qm-title-bar {
  color: #001269;
  font-size: 2em;
  margin-left: -40px;
  margin-right: 10px;
}
#qm-title-btn {
  border-radius: 0;
  font-size: 12px;
  color: #001269;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
#info-graph-icon {
  border-radius: 50%;
  color: #001269;
  font-size: 18px;
}

#qm-info-btn {
  padding: 3px;
  margin-bottom: 3px;
}

#pe-info-btn {
  padding: 3px;
  margin-bottom: -3px;
}
#function-icon-btn {
  position: absolute;
  z-index: 1;
  right: 22px;
  padding: 5px;
  top: 43px;
}

#info-graph-icon-btn {
  padding: 0px;
  margin: 0px 2px 3.5px;
}

#graph-icon-btn {
  position: absolute;
  right: 2px;
  margin-top: 100px;
  padding: 2px;
  border-radius: 10%;
}
#arrow-graph-icon {
  font-size: 1.2em;
  color: #001269;
}

#bar-icon {
  border-radius: 50%;
  color: #62717a;
}
#bar-icon-btn {
  padding: 7px;
  margin: 5px;
}
#qm {
  font-size: 24px;
  margin: 0px 5px -8px;
}
#donut {
  font-size: 24px;
  margin-bottom: -4px;
  padding: 0 2px 0 0;
}
#pe-doughnut-score {
  color: #001269;
  font-size: 24px;
  margin-left: 10px;
  margin-top: 30px;
}
#doughnut-score {
  color: #001269;
  font-size: 24px;
  margin-left: 10px;
  margin-bottom: 25px;
}

@font-face {
  font-family: "MyriadWebPro";
  src: local("MyriadWebPro"), url(assets/fonts/MyriadWebPro.ttf) format("ttf");
}

@font-face {
  font-family: "MyriadWebProBold";
  src: local("MyriadWebProBold"), url(assets/fonts/MyriadWebProBold.ttf) format("ttf");
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(assets/fonts/Avenir.ttc) format("ttc");
}

@font-face {
  font-family: "AvenirBook";
  src: local("Avenir-Book"), url(assets/fonts/Avenir-Book.ttf) format("ttf");
}
#big {
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  text-transform: capitalize;
}
